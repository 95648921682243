import type { State } from "./b2x-state";
import w from "./window";
import { batch } from "./batch";

const STATE = "state";

export type StateRecord<T = unknown> = {
  type: typeof STATE;
  expires: number;
  state: T;
};

const stringify = (data: unknown) => {
  try {
    return JSON.stringify(data);
  } catch {
    // eslint-disable-next-line
    console.error("State can't be a cyclical object or contain a BigInt value");
    return null;
  }
};

export const parse = <T>(str?: string | null): StateRecord<T> | null => {
  try {
    return JSON.parse(str ?? "null");
  } catch {
    return null;
  }
};

export const hours2Ms = (h: number): number => h * (1000 * 60 * 60);

export const createStateRecord = <T>(state: T, ttl: number): StateRecord<T> => ({
  type: STATE,
  expires: ttl ? Date.now() + hours2Ms(ttl) : 0,
  state,
});

export const isRecord = <T extends unknown>(
  state: State<T> | StateRecord<T> | null
): state is StateRecord<T> =>
  Boolean(Object(state) === state && "expires" in (state as Record<string, unknown>));

export const hasExpired = <T>(state: State<T> | StateRecord<T>): boolean =>
  isRecord(state) && state.expires !== 0 && Date.now() > state.expires;

const removeItem = (key: string): null => {
  w?.localStorage.removeItem(key);

  return null;
};

export const storage = {
  getItem<T>(key: string, storedItem?: string | null): StateRecord<T> | null {
    const state = parse<T>(storedItem ?? w?.localStorage.getItem(key));

    return hasExpired(state) ? removeItem(key) : state;
  },
  setItem<T>(key: string, state: State<T>, ttl: number): StateRecord<T> | null {
    if (state === null) return removeItem(key);

    const record = createStateRecord(state, ttl);
    const str = stringify(record);

    if (typeof str === "string") batch(key, () => w?.localStorage.setItem(key, str));

    return record;
  },
  removeItem,
};

export const prune = (removeAll = false): void =>
  w &&
  Object.entries(w.localStorage).forEach(([key, value]) => {
    /* eslint-disable */
    const isTeliaState = typeof value === "string" && value.includes(`\"type\":\"${STATE}\"`);
    /* eslint-enable */

    if (isTeliaState && (removeAll || hasExpired(parse(value)))) removeItem(key);
  });
