import { storage, hasExpired, isRecord, StateRecord } from "./storage";
import w from "./window";

const DEFAULT_NAMESPACE = "telia-state-";
const DEFAULT_TTL = 336; // 2 weeks (hours)

export type State<T, N = null> = null extends N ? T | null : T;
type StateSetter<T, N> = (state: State<T, N>) => State<T, N>;

export interface SetState<T, N> {
  (newState: State<T, N>, ttl?: number): void;
  (setter: StateSetter<T, N>, ttl?: number): void;
}

export type Callback<C> = (state: C) => void;

export type Store<T extends unknown, N = null> = {
  subscribe: (callback: Callback<State<T, N>>) => () => boolean;
  getState: () => State<T, N>;
  setState: SetState<T, N>;
  end: null extends N ? (erase?: boolean, reason?: string) => void : (reason?: string) => void;
};

type CommonOptions<P, T = unknown> = {
  /** Opt-out of the persistent storage functionality by setting this to false */
  persistent?: P;
  /** Initial state of the store, also sets the store state type */
  initialState?: T;
};

type PersistentOptions<O, E, P, T = unknown> = {
  /** Overwrite any existing state in local storage with the provided initial state */
  overwriteExisting?: O;
  /** Prefix to use in local storage */
  namespace?: string;
  /** Time in hours before removed from client's local storage */
  ttl?: E;
} & CommonOptions<P, T>;

type Options<M, P, O, E, T = unknown> = P extends true
  ? M extends true
    ? Omit<PersistentOptions<O, E, P>, "initialState">
    : PersistentOptions<O, E, P, T>
  : M extends true
  ? Omit<CommonOptions<P>, "initialState">
  : CommonOptions<P, T>;

// eslint-disable-next-line
export const makeCreateStore = <
  OO extends boolean,
  EE extends number,
  PP extends boolean | undefined = false
>(
  defaultOptions?: Options<true, PP, OO, EE>
) => {
  const o = defaultOptions as PersistentOptions<OO, EE, PP>;
  const defaults = {
    namespace: o?.namespace ?? DEFAULT_NAMESPACE,
    overwriteExisting: o?.overwriteExisting ?? false,
    persistent: o?.persistent ?? false,
    ttl: o?.ttl ?? DEFAULT_TTL,
  };

  return <
    T = unknown,
    O extends boolean = OO,
    E extends number = EE,
    P extends boolean | undefined = PP,
    R = P extends true ? (O extends true ? (E extends 0 ? never : null) : null) : never
  >(
    name: string,
    options?: Options<false, P, O, E, T>
  ): Store<T, R> => {
    const { overwriteExisting, namespace, ttl, persistent, initialState = undefined } = {
      ...defaults,
      ...options,
    };
    const subscribers = new Set<Callback<State<T, R>>>();
    const key = `${namespace}${name}`;

    const getStateRecord = (storedItem?: string | null) => storage.getItem<T>(key, storedItem);
    const saveStateToStore = (state: State<T>, _ttl = ttl) =>
      persistent ? storage.setItem(key, state, _ttl) : state;

    const existingState = persistent ? getStateRecord() : null;

    // mutable vars
    let suspended: false | string = false;
    let cache: StateRecord<T> | State<T, null> = null;

    const getCachedState = (check = true): State<T, R> => {
      if (check && hasExpired(cache)) cache = storage.removeItem(key);

      return (isRecord(cache) ? cache.state : cache) as State<T, R>;
    };

    const publish = () => {
      const state = getCachedState(false);
      subscribers.forEach((f) => f(state));
    };

    const setState = (arg: State<T, R> | StateSetter<T, R>, ttl?: number) => {
      // eslint-disable-next-line
      if (suspended) return console.warn(suspended);

      const currentState = getCachedState();
      const newState = typeof arg === "function" ? (arg as StateSetter<T, R>)(currentState) : arg;

      if (newState === currentState) return;

      cache = saveStateToStore(newState, ttl);
      publish();
    };

    const storageListener = (e: StorageEvent) => {
      if (e.key !== key) return;

      cache = getStateRecord(e.newValue);
      publish();
    };

    const end = (ceaseReason?: string) => {
      if (suspended && !ceaseReason) setup();

      subscribers.clear();
      suspended = ceaseReason ?? false;
    };

    const setup = () => {
      if (persistent) w?.addEventListener("storage", storageListener);

      cache =
        (overwriteExisting || !existingState) && initialState !== undefined
          ? saveStateToStore(initialState)
          : existingState;

      return (arg1?: boolean | string, arg2?: boolean | string) => {
        const reason = persistent ? arg2 : arg1;

        if (persistent && arg1) storage.removeItem(key);

        end(
          typeof reason === "string"
            ? reason
            : "This store has been suspended and is not active anymore."
        );
      };
    };

    return {
      subscribe: (cb: Callback<State<T, R>>) => {
        // eslint-disable-next-line
        if (suspended) console.warn(suspended);
        else subscribers.add(cb);

        return () => subscribers.delete(cb);
      },
      getState: () => getCachedState(),
      setState,
      end: setup(),
    };
  };
};

export const createStore = makeCreateStore();

export * from "./useGlobalState";
